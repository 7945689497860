// See http://www.paulirish.com/2009/bulletproof-font-face-implementation-syntax/
@mixin liberation_sans($directory, $prefix, $svg_id) {
  font-family: 'Liberation Sans';
  $eot-ie9: "/assets/fonts/liberation_sans/#{$directory}/#{$prefix}.eot";
  $eot: "/assets/fonts/liberation_sans/#{$directory}/#{$prefix}.eot?#iefix";
  $woff: "/assets/fonts/liberation_sans/#{$directory}/#{$prefix}.woff";
  $ttf: "/assets/fonts/liberation_sans/#{$directory}/#{$prefix}.ttf";
  $svg: "/assets/fonts/liberation_sans/#{$directory}/#{$prefix}.svg##{$svg_id}";
  src: url(@eot-ie9); // IE 9 compatibility mode
  src:
    url($eot) format('embedded-opentype'), //IE 6 to IE 8
    url($woff) format('woff'), // Sane browsers
    url($ttf) format('truetype'), // Safari, Android, iOS
    url($svg) format('svg'); // Legacy iOS
}

@font-face {
  @include liberation_sans('bold', 'LiberationSans-Bold', 'liberation_sansbold');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  @include liberation_sans('bolditalic', 'LiberationSans-BoldItalic', 'liberation_sansbold_italic');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  @include liberation_sans('italic', 'LiberationSans-Italic', 'liberation_sansitalic');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  @include liberation_sans('regular', 'LiberationSans-Regular', 'liberation_sansregular');
  font-weight: normal;
  font-style: normal;
}

@mixin liberation_mono($directory, $prefix, $svg_id) {
  font-family: 'Liberation Mono';
  $eot-ie9: "/assets/fonts/liberation_mono/#{$directory}/#{$prefix}.eot";
  $eot: "/assets/fonts/liberation_mono/#{$directory}/#{$prefix}.eot?#iefix";
  $woff: "/assets/fonts/liberation_mono/#{$directory}/#{$prefix}.woff";
  $ttf: "/assets/fonts/liberation_mono/#{$directory}/#{$prefix}.ttf";
  $svg: "/assets/fonts/liberation_mono/#{directory}/#{$prefix}.svg##{$svg_id}";
  src: url($eot-ie9); // IE 9 compatibility mode
  src:
    url($eot) format('embedded-opentype'), //IE 6 to IE 8
    url($woff) format('woff'), // Sane browsers
    url($ttf) format('truetype'), // Safari, Android, iOS
    url($svg) format('svg'); // Legacy iOS
}

@font-face {
  @include liberation_mono('bold', 'LiberationMono-Bold', 'liberation_monobold');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  @include liberation_mono('bolditalic', 'LiberationMono-BoldItalic', 'liberation_monobold_italic');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  @include liberation_mono('italic', 'LiberationMono-Italic', 'liberation_monoitalic');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  @include liberation_mono('regular', 'LiberationMono-Regular', 'liberation_monoregular');
  font-weight: normal;
  font-style: normal;
}

@mixin liberation_serif($directory, $prefix, $svg_id) {
  font-family: 'Liberation Serif';
  $eot-ie9: "/assets/fonts/liberation_serif/#{$directory}/#{$prefix}.eot";
  $eot: "/assets/fonts/liberation_serif/#{$directory}/#{$prefix}.eot?#iefix";
  $woff: "/assets/fonts/liberation_serif/#{$directory}/#{$prefix}.woff";
  $ttf: "/assets/fonts/liberation_serif/#{$directory}/#{$prefix}.ttf";
  $svg: "/assets/fonts/liberation_serif/#{$directory}/#{$prefix}.svg##{$svg_id}";
  src: url(@eot-ie9); // IE 9 compatibility mode
  src:
    url($eot) format('embedded-opentype'), //IE 6 to IE 8
    url($woff) format('woff'), // Sane browsers
    url($ttf) format('truetype'), // Safari, Android, iOS
    url($svg) format('svg'); // Legacy iOS
}

@font-face {
  @include liberation_serif('bold', 'LiberationSerif-Bold', 'liberation_serifbold');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  @include liberation_serif('bolditalic', 'LiberationSerif-BoldItalic', 'liberation_serifbold_italic');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  @include liberation_serif('italic', 'LiberationSerif-Italic', 'liberation_serifitalic');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  @include liberation_serif('regular', 'LiberationSerif-Regular', 'liberation_serifregular');
  font-weight: normal;
  font-style: normal;
}

@mixin overpass($directory, $prefix, $svg_id) {
  font-family: 'Overpass';
  $eot-ie9: "/assets/fonts/overpass/#{$directory}/#{$prefix}.eot";
  $eot: "/assets/fonts/overpass/#{$directory}/#{$prefix}.eot?#iefix"; 
  $woff: "/assets/fonts/overpass/#{$directory}/#{$prefix}.woff";
  $ttf: "/assets/fonts/overpass/#{$directory}/#{$prefix}.ttf";
  $svg: "/assets/fonts/overpass/#{$directory}/#{$prefix}.svg##{$svg_id}";
  src: url($eot-ie9); // IE 9 compatibility mode
  src:
    url($eot) format('embedded-opentype'), //IE 6 to IE 8
    url($woff) format('woff'), // Sane browsers
    url($ttf) format('truetype'), // Safari, Android, iOS
    url($svg) format('svg'); // Legacy iOS
}

@font-face {
  @include overpass('regular', 'overpass_regular', 'webfontLTZe4IYH');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  @include overpass('bold', 'overpass_bold', 'webfontzAU82Ltw');
  font-weight: bold;
  font-style: normal;
}

@mixin roboto($directory, $prefix, $svg_id) {
  font-family: 'Roboto';
  $eot-ie9: "/assets/fonts/roboto/#{$directory}/#{$prefix}.eot";
  $eot: "/assets/fonts/roboto/#{$directory}/#{$prefix}.eot?#iefix";
  $woff: "/assets/fonts/roboto/#{$directory}/#{$prefix}.woff";
  $ttf: "/assets/fonts/roboto/#{$directory}/#{$prefix}.ttf";
  $svg: "/assets/fonts/roboto/#{$directory}/#{$prefix}.svg##{$svg_id}";
  src: url($eot-ie9); // IE 9 compatibility mode
  src:
    url($eot) format('embedded-opentype'), //IE 6 to IE 8
    url($woff) format('woff'), // Sane browsers
    url($ttf) format('truetype'), // Safari, Android, iOS
    url($svg) format('svg'); // Legacy iOS
}

@font-face {
  @include roboto('bold', 'Roboto-Bold', 'robotobold');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  @include roboto('bolditalic', 'Roboto-BoldItalic', 'robotobold_italic');
  font-weight: bold;
  font-style: italic;
}
