// Variable overrides
$link-color: #4180ab;

$font-family-sans-serif: "Liberation Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace: "Liberation Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-serif: "Liberation Serif", Georgia, "Times New Roman", Times, serif;

$icon-font-path: "bootstrap/fonts/" !default;

$navbar-default-color: #000;
$navbar-default-bg: #333;

$navbar-default-link-color: #fff;
$navbar-default-link-hover-color: #ccc;
$navbar-default-link-active-color: #bbb;
$navbar-default-link-disabled-color: #333;

$navbar-margin-bottom: 0px;
